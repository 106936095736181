(function () {
	'use strict';

	let nextButton = document.querySelector('.splide__arrow--next');
	if(nextButton) {
	    nextButton.addEventListener('click', (e) => {
		imageCarousel.go('>');
	});
	const imageCarousel = new Splide("#slider", {
		type: "slide",
		perPage: 2,
		perMove: 1,
		focus: 'left',
		trimSpace: false,
		pagination: false,
		arrows: false,
		// rewind: true,
		drag: false,
		waitForTransition: true,
		updateOnMove: true,
		breakpoints: {
			980: { perPage: 1, type:"loop"},
			640 : { perPage:1,type:"loop",drag: true},
		  },
	});
	imageCarousel.mount();

	}

	const burgerBtn = document.querySelector('.hamburger');
	const menu = document.querySelector('.--mobile');
	const link = document.querySelectorAll('.header__menu-link');
	const body = document.querySelector('body');

	burgerBtn.addEventListener('click', () => {
		body.classList.toggle('scroll-off');
		menu.classList.toggle('active-menu');
	});

	link.forEach((link) =>{
		link.addEventListener('click', () =>{
			menu.classList.remove('active-menu');
		});
	});

	let tabNav = document.querySelector('.tab-nav'),
		ddBtn = document.querySelector('.btn-dropdown'),
		tabTrigger = document.querySelectorAll('.tab-trigger');


	tabTrigger.forEach((item) => {
		item.addEventListener('click', (e) =>{
			console.log(e.target.innerHTML);
			ddBtn.innerHTML = e.target.innerHTML;
			if(tabNav.classList.contains('--show')){
				tabNav.classList.remove('--show');
			}
		});
	});	

	let showDropdown = (e) => {
		tabNav.classList.toggle('--show');
	};
	if(tabNav) {ddBtn.addEventListener('click', showDropdown);}

	// --- Page preloader ---
	const LayoutPagePreloader = (() => {

		class Core {

		    constructor(item) {
		        
		        this.item = item;
		        this.build();

		    }


		    build() {

		    	let preloaderStorage = localStorage.getItem('page-preloader');


		    	setTimeout(() => {
		    		
		    		this.item.classList.remove('page-preloader');	    		
		    		if (preloaderStorage !== 'on') localStorage.setItem('page-preloader', 'on');

		    	}, 1500);

		    }	

		}


		// init
		const init = () => new Core(document.documentElement);


		return { init };

	})();


	window.LayoutPagePreloader = LayoutPagePreloader;
	// --- /Page preloader ---

	console.info('main js');


	// init
	document.addEventListener('DOMContentLoaded', () => {

		// layouts
		LayoutPagePreloader.init();
			
	});

})();
