let tabNav = document.querySelector('.tab-nav'),
	ddBtn = document.querySelector('.btn-dropdown'),
	tabTrigger = document.querySelectorAll('.tab-trigger')


tabTrigger.forEach((item) => {
	item.addEventListener('click', (e) =>{
		console.log(e.target.innerHTML)
		ddBtn.innerHTML = e.target.innerHTML
		if(tabNav.classList.contains('--show')){
			tabNav.classList.remove('--show')
		}
	})
})	

let showDropdown = (e) => {
	tabNav.classList.toggle('--show')
}
if(tabNav) {ddBtn.addEventListener('click', showDropdown)}


