/**
 * Basic UI: Dropdown.
 */
const BasicDropdown = (() => {

    class Core {

        constructor(element) {

            this.$trigger = element;
            this.$menu = document.getElementById(this.$trigger.getAttribute('data-dropdown-menu'));            
            
            this.variables();
            this.build();
            this.event();                         
        
        }


        variables() {

            let dataOptions = JSON.parse(this.$trigger.getAttribute('data-dropdown-options')) || {};
            
            // options            
            this.$options = {                
                event:    (dataOptions['event']    || 'click'),       
                position: (dataOptions['position'] || 'bottom'),
                className: {
                    selected: 'dropdown-menu--selected'
                }            
            };

            // custom event
            this.$customEventToggle = new CustomEvent('dropdown toggle', {
				bubbles: true,
				detail: {
                    'trigger': this.$trigger,
                    'menu':    this.$menu,
                    'show':    false
				}
			});
                        
            this.funcClickByArea = (event) => {

                let target = event.target;

                if (target.closest('.dropdown-menu') == null && target.closest('.dropdown-trigger') == null) {
                    
                    this.$trigger.setAttribute('aria-expanded', false);
                    this.$menu.classList.remove(this.$options.className.selected);

                    document.removeEventListener('click', this.funcClickByArea, false);

                }

            }

        }


        build() {

            if (this.$trigger.classList.contains('dropdown-init') == false) {

                this.$trigger.classList.add('dropdown-trigger');
                this.$trigger.classList.add('dropdown-init');
                this.$options.index = Array.from(document.querySelectorAll('.dropdown-init')).indexOf(this.$trigger);
                            
                let id = this.$trigger.getAttribute('id') || `dropdown-trigger-${this.$options.index}`;

                this.$trigger.setAttribute('id', id);
                this.$trigger.setAttribute('aria-haspopup', true);
                this.$trigger.setAttribute('aria-expanded', false);

                
                this.position();            
                this.$menu.setAttribute('aria-labelledby', this.$trigger.getAttribute('id'));
                document.body.appendChild(this.$menu);
                            
            }

        }


        event() {

            this.$trigger.addEventListener(this.$options['event'], this.listener_dropdown_visible.bind(this), false);

        }


        listener_dropdown_visible() {

            let status = !this.$menu.classList.contains(this.$options.className.selected);
            

            if (status == true) this.position();
            this.$trigger.setAttribute('aria-expanded', status);                        
            this.$menu.classList.toggle(this.$options.className.selected);
 

            if (this.$menu.classList.contains(this.$options.className.selected) == true) {

                document.addEventListener('click', this.funcClickByArea, false);

            }


            // custom event: dropdown switch
			this.$customEventToggle.detail.trigger = this.$trigger;
			this.$customEventToggle.detail.menu = this.$menu;
			this.$customEventToggle.detail.show = status;
			this.$trigger.dispatchEvent(this.$customEventToggle);            
            
        }


        method_show() {

            this.position();
            this.$trigger.setAttribute('aria-expanded', true);
            this.$menu.classList.add(this.$options.className.selected);

            document.addEventListener('click', this.funcClickByArea, false);

        }


        method_hide() {

            this.position();
            this.$trigger.setAttribute('aria-expanded', false);
            this.$menu.classList.remove(this.$options.className.selected);

            document.removeEventListener('click', this.funcClickByArea, false);

        }


        position() {

            // desktop
            if (window.innerWidth > 650) {

                // 1.top
                if (this.$options.position == 'top' || this.$options.position == 'top-left' || this.$options.position == 'top-right') {
        
                    this.top();
                
                }
    
                // 2.right		
                if (this.$options.position == 'right') {
    
                    this.right();
    
                }
    
                //  3.bottom
                if (this.$options.position == 'bottom' || this.$options.position == 'bottom-left') {
    
                    this.bottom();
    
                }
                
                // 4.left
                if (this.$options.position == 'left') {
    
                    this.left();
    
                }
            
            } 
            

            // mobile
            if (window.innerWidth <= 650) {

                let position = {
                    name: '',
                    value: '',
                };
                
                
                // bottom
                position.name = 'bottom';
                position.value = `${this.$trigger.getBoundingClientRect().top + window.pageYOffset + this.$trigger.getBoundingClientRect().height + 8}px`;
                this.$menu.style.top = position.value;
                                
                // top
                if (this.$menu.getBoundingClientRect().bottom > (window.innerHeight - 20)) {
                    
                    position.name = 'top';
                    position.value = `${this.$trigger.getBoundingClientRect().top + window.pageYOffset - this.$menu.getBoundingClientRect().height - 8}px`;
                
                }
                
                
                this.$menu.classList.remove(this.$options.position);
                this.$menu.classList.add(position.name);
                this.$menu.style.top = position.value;
                this.$options.position = position.name;

            }

		}


		top() {

			let coordTrigger = this.$trigger.getBoundingClientRect(),
				coordMenu = this.$menu.getBoundingClientRect(),
				position = {
					name : 'top',
					top: 0,
					right: coordTrigger.right + 8,
					left: coordTrigger.left  + (coordTrigger.width / 2) - (this.$menu.offsetWidth / 2)
				},				

				status = {
					right: ((position.right + coordMenu.width) < window.innerWidth),
					left: (position.left < 0)
				};
			
			
			// top
			if (coordMenu.top > 20) {
				
				if (!status.left && status.right)  position.name = 'top'; 		  				
				if (status.left  && status.right)  position.name = 'top-left';    								
				if (!status.left && !status.right) position.name = 'top-right';
				
	    		position.top = coordTrigger.top + window.pageYOffset - coordMenu.height - 8;
			
			// bottom
			} else {

				if (status.left && status.right) position.name = 'bottom-left';
				if (!status.left && status.right) position.name = 'bottom';
				
				position.top = coordTrigger.top + window.pageYOffset + coordTrigger.height + 8;

			}


			position.left = (status.left) ? (coordTrigger.left - 5) : position.left;
			position.left = (!status.left && !status.right) ? (coordTrigger.right - this.$menu.offsetWidth + 5) : position.left;


			// result
			this.$menu.classList.remove(this.$options.position);	
			this.$menu.classList.add(position.name);	
			this.$menu.style.top  = `${position.top}px`;
			this.$menu.style.left = `${position.left}px`;	
			this.$options.position = position.name;	

		}


		bottom() {

			let coordTrigger = this.$trigger.getBoundingClientRect(),
				coordMenu = this.$menu.getBoundingClientRect(),
				position = {
					top: 0,
					name: 'bottom',
					left: coordTrigger.left  + (coordTrigger.width / 2) - (this.$menu.offsetWidth / 2)
				};

            
			if (coordMenu.bottom < (window.innerHeight - 20)) {

				if (position.left >= 0) position.name = 'bottom';
				if (position.left < 0)  position.name = 'bottom-left';
				
				position.top = coordTrigger.top + window.pageYOffset + coordTrigger.height + 8;
			
			} else {

				if (position.left >= 0) position.name = 'top';
				if (position.left < 0)  position.name = 'top-left';
					    		
	    		position.top = coordTrigger.top + window.pageYOffset - coordMenu.height - 8;
			
			}

	
			position.left = (position.left < 0) ? coordTrigger.left : position.left;


			// result
			this.$menu.classList.remove(this.$options.position);	
			this.$menu.classList.add(position.name);	
			this.$menu.style.top  = `${position.top}px`;
			this.$menu.style.left = `${position.left}px`;
			this.$options.position = position.name;	
		
		}


		left() {

			let coordTrigger = this.$trigger.getBoundingClientRect(),
				coordMenu = this.$menu.getBoundingClientRect(),
				positionLeft = coordTrigger.left - coordMenu.width - 8;

				
			if (positionLeft > 0) {
                
	    		this.$menu.classList.remove(this.$options.position);
                this.$menu.classList.add('left');
				this.$menu.style.top = `${coordTrigger.top + window.pageYOffset}px`;
				this.$menu.style.left = `${positionLeft}px`;
				this.$options.position = 'left';

			} else {
				
				this.top();
			
			}
		
		}


		right() {

			let coordTrigger = this.$trigger.getBoundingClientRect(),
				coordMenu = this.$menu.getBoundingClientRect(),
				positionRight = coordTrigger.right + 8;


			if ((positionRight + this.$menu.getBoundingClientRect().width) < window.innerWidth) {
                
                this.$menu.classList.remove(this.$options.position);
                this.$menu.classList.add('right');					
				this.$menu.style.top = `${coordTrigger.top + window.pageYOffset}px`;
				this.$menu.style.left = `${positionRight}px`;
				this.$options.position = 'right';

			} else {

				this.top();
			
			}
		
		}

    };


    let active = null;


    // init
    const init = (variable) => {

        let element = null,
            elements = BasicCore.variables(variable, '.js-dropdown-trigger:not(.dropdown-init)');            


		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
            
            elements.forEach((value) => {  

                element = value;                
                active = new Core(element);                
            
            });

            return active;
        
        } catch(error) {

			console.error(`${BasicCore.logging['name']} Dropdown init. \nMessage: ${error.message} \nElement: `, element);

		}

    }


    // show
    const show = (variable) => {

        let element = (typeof variable == 'object') ? variable : document.querySelector(variable)            


		try {

			if (element == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (element == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
            
            active = new Core(element);
            active.method_show();

            return active;
        
        } catch(error) {

			console.error(`${BasicCore.logging['name']} Dropdown show. \nMessage: ${error.message} \nElement: `, element);

		}

    }


    // hide
    const hide = (variable) => {

        let element = (typeof variable == 'object') ? variable : document.querySelector(variable)            


		try {

			if (element == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (element == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
            
            active = new Core(element);
            active.method_hide();

            return active;
        
        } catch(error) {

			console.error(`${BasicCore.logging['name']} Dropdown hide. \nMessage: ${error.message} \nElement: `, element);

		}

    }


    return { init, show, hide };

})()


window.BasicDropdown = BasicDropdown;


export { BasicDropdown };