/**
 * Basic UI: Tooltip.
 */
 const BasicTooltip = (() => {

	class Core {

	    constructor({trigger, content, mobile, mobileScreen, addClass}) {
	        
	        this.$trigger 	= trigger;			
			this.$container = document.querySelector('.tooltip');
			this.$content 	= content || this.$trigger.getAttribute('data-tooltip-content');

			this.$options = {
				mobile:   	  mobile 	   || true,
				mobileScreen: mobileScreen || 480,
				addClass: 	  addClass 	   || 'tooltip--default',
				position: 	  this.$trigger.getAttribute('data-tooltip-position') || 'top',
				className: {
					show: 'tooltip--show'
				}
			}
					
			this.$customEvent = new CustomEvent('tooltip event', { 
                bubbles: true,
                detail:  {
                    'trigger': null,
                    'tooltip': this.$container,
					'hidden': true
                }
            });
			

			// start
			if (this.$trigger.classList.contains('tooltip-trigger') == false) {

				this.init();
				this.events();

			}			
	        		    	
	    }


		init() {

			this.$trigger.classList.add('tooltip-trigger');
			this.$trigger.setAttribute('aria-describedby', 'tooltip');						

		}


	    events() {

			// show
			this.$trigger.addEventListener('mouseover', this.listener_show.bind(this), false);
			this.$trigger.addEventListener('focus', this.listener_show.bind(this), false);

			
			// hide	
			this.$trigger.addEventListener('blur', this.listener_hide.bind(this), false);
			this.$trigger.addEventListener('mouseout', this.listener_hide.bind(this), false);

		}


		listener_show() {					
			
			this.$container.setAttribute('style', 
				`--triggerWidth: ${Math.round(this.$trigger.getBoundingClientRect().width)}px; 
				--triggerHeight: ${Math.round(this.$trigger.getBoundingClientRect().height)}px`
			);
			this.$container.classList.add(this.$options.addClass);	    	    		    							
			this.$container.querySelector('.tooltip-content').innerHTML = this.$content;		
	    	

			this.position();
			this.$container.classList.add(this.$options.className.show);


			// custom event: tooltip event
			this.$customEvent.detail.trigger = this.$trigger;
			this.$customEvent.detail.tooltip = this.$container;
			this.$customEvent.detail.hidden = false;
			this.$trigger.dispatchEvent(this.$customEvent); 

		}


		listener_hide() {

			this.$container.classList.remove(this.$options.className.show);			
			this.$container.classList.remove(this.$options.position);
			this.$container.classList.remove(this.$options.addClass);


			// custom event: tooltip event
			this.$customEvent.detail.trigger = this.$trigger;
			this.$customEvent.detail.tooltip = this.$container;
			this.$customEvent.detail.hidden = true;
			this.$trigger.dispatchEvent(this.$customEvent); 

		}


		position() {

			// mobile true
			if (this.$options.mobile == true) {

				if (window.innerWidth <= this.$options.mobileScreen) {

					this.position_mobile();
				
				} else {

					this.$container.classList.remove('top-default');
					this.position_desktop();

				}	

			}
			
			
			// mobile false
			if (this.$options.mobile == false) {

				this.position_desktop();

			}

		}


		position_desktop() {

			// 1.top
			if (this.$options.position == 'top' || this.$options.position == 'top-left' || this.$options.position == 'top-right') {
	
				this.top();
			
			}

			// 4.right		
			if (this.$options.position == 'right') {

				this.right();

			}

			//  2.bottom
			if (this.$options.position == 'bottom' || this.$options.position == 'bottom-left') {

				this.bottom();

			}
			
			// 3.left
			if (this.$options.position == 'left') {

				this.left();

			}

		}


		position_mobile() {
			
			this.$container.classList.add('top-default');
		    this.$container.style = '';		    		
		    this.$container.style.top = `${this.$trigger.getBoundingClientRect().top + window.pageYOffset - this.$container.getBoundingClientRect().height - 8}px`;
				
		}


		top() {

			let coordTrigger = this.$trigger.getBoundingClientRect(),
				coordTooltip = this.$container.getBoundingClientRect(),
				position = {
					name : 'top',
					top: 0,
					right: coordTrigger.right + 8,
					left: coordTrigger.left  + (coordTrigger.width / 2) - (this.$container.offsetWidth / 2)
				},				

				status = {
					right: ((position.right + coordTooltip.width) < window.innerWidth),
					left: (position.left < 0)
				};
			
			
			// top
			if (coordTrigger.top > 20) {
				
				if (!status.left && status.right)  position.name = 'top'; 		  				
				if (status.left  && status.right)  position.name = 'top-left';    								
				if (!status.left && !status.right) position.name = 'top-right';
				
	    		position.top = coordTrigger.top + window.pageYOffset - coordTooltip.height - 8;
			
			// bottom
			} else {

				if (status.left && status.right) position.name = 'bottom-left';
				if (!status.left && status.right) position.name = 'bottom';
				
				position.top = coordTrigger.top + window.pageYOffset + coordTrigger.height + 8;

			}


			position.left = (status.left) ? (coordTrigger.left - 5) : position.left;
			position.left = (!status.left && !status.right) ? (coordTrigger.right - this.$container.offsetWidth + 5) : position.left;


			// result
			this.$options.position = position.name;	
			this.$container.classList.add(position.name);	
			this.$container.style.top  = `${position.top}px`;
			this.$container.style.left = `${position.left}px`;	

		}


		bottom() {

			let coordTrigger = this.$trigger.getBoundingClientRect(),
				coordTooltip = this.$container.getBoundingClientRect(),
				position = {
					top: 0,
					name: 'bottom',
					left: coordTrigger.left  + (coordTrigger.width / 2) - (this.$container.offsetWidth / 2)
				};


			if (coordTrigger.bottom < (window.innerHeight - 20)) {

				if (position.left >= 0) position.name = 'bottom';
				if (position.left < 0)  position.name = 'bottom-left';
				
				position.top = coordTrigger.top + window.pageYOffset + coordTrigger.height + 8;
			
			} else {

				if (position.left >= 0) position.name = 'top';
				if (position.left < 0)  position.name = 'top-left';
					    		
	    		position.top = coordTrigger.top + window.pageYOffset - coordTooltip.height - 8;
			
			}

	
			position.left = (position.left < 0) ? coordTrigger.left : position.left;


			// result
			this.$options.position = position.name;	
			this.$container.classList.add(position.name);	
			this.$container.style.top  = `${position.top}px`;
			this.$container.style.left = `${position.left}px`;
		
		}


		left() {

			let coordTrigger = this.$trigger.getBoundingClientRect(),
				coordTooltip = this.$container.getBoundingClientRect(),
				positionLeft = coordTrigger.left - coordTooltip.width - 8;

				
			if (positionLeft > 0) {

				this.$options.position = 'left';

	    		this.$container.classList.add('left');
				this.$container.style.top = `${coordTrigger.top + window.pageYOffset + (coordTrigger.height / 2) - (coordTooltip.height / 2)}px`;
				this.$container.style.left = `${positionLeft}px`;

			} else {
				
				this.top();
			
			}
		
		}


		right() {

			let coordTrigger = this.$trigger.getBoundingClientRect(),
				coordTooltip = this.$container.getBoundingClientRect(),
				positionRight = coordTrigger.right + 8;


			if ((positionRight + this.$container.getBoundingClientRect().width) < window.innerWidth) {

				this.$options.position = 'right';

	    		this.$container.classList.add('right');					
				this.$container.style.top = `${coordTrigger.top + window.pageYOffset + (coordTrigger.height / 2) - (coordTooltip.height / 2)}px`;
				this.$container.style.left = `${positionRight}px`;

			} else {

				this.top();
			
			}
		
		}


		method_show() {

			this.listener_show();

		}

	}


	let active = null;


	/**
	 * Tooltip initialization
	 * @param {object|string} config - configuration tooltip
	 */
	const init = (config) => {

		let container = null,
			options = config || {},			
			elements = BasicCore.variables(options.trigger, '[data-tooltip-content]');

			
		// build container
		container = document.createElement('div');		
		container.classList.add('tooltip');
		container.setAttribute('role', 'tooltip');			
		container.innerHTML = '<div class="tooltip-content"></div>';				
		
		document.body.appendChild(container);
		// /build container


		try {

			if (elements == false && options.tooltip !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && options.tooltip !== undefined) throw new Error(BasicCore.logging['error']['type']);
	
			elements.forEach((value) => {

				options.trigger = value;
				active = new Core(options);
				
			});

			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Tooltip init. \nMessage: ${error.message} \nElement: `, options.trigger);

		}

	}


	/**
	 * Tooltip method: Show
	 * @param {object|string} config - configuration tooltip
	 */
	const show = (config) => {

		let options = config || {};			
		
		options.trigger = (typeof options.trigger == 'object') ? options.trigger : document.querySelector(options.trigger) ;
		
		try {

			if (options.trigger == null) throw new Error(BasicCore.logging['error']['type']);
	
			active = new Core(options);
			active.method_show();

			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Tooltip show. \nMessage: ${error.message} \nElement: `, options.trigger);

		}

	}


	return { init, show };

})()


window.BasicTooltip = BasicTooltip;


export { BasicTooltip };
// --- /ToolTip ---