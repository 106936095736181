let nextButton = document.querySelector('.splide__arrow--next');
if(nextButton) {
    nextButton.addEventListener('click', (e) => {
	imageCarousel.go('>')
})
const imageCarousel = new Splide("#slider", {
	type: "slide",
	perPage: 2,
	perMove: 1,
	focus: 'left',
	trimSpace: false,
	pagination: false,
	arrows: false,
	// rewind: true,
	drag: false,
	waitForTransition: true,
	updateOnMove: true,
	breakpoints: {
		980: { perPage: 1, type:"loop"},
		640 : { perPage:1,type:"loop",drag: true},
	  },
});
imageCarousel.mount();

}


