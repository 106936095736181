const burgerBtn = document.querySelector('.hamburger')
const menu = document.querySelector('.--mobile')
const link = document.querySelectorAll('.header__menu-link')
const body = document.querySelector('body')

burgerBtn.addEventListener('click', () => {
	body.classList.toggle('scroll-off')
	menu.classList.toggle('active-menu')
})

link.forEach((link) =>{
	link.addEventListener('click', () =>{
		menu.classList.remove('active-menu')
	})
})
