console.info('main js');

import './init/splide.js' //инит слайдера
import './burger/burger.js' // бургер меню
import './dropdown/dropdown.js' //dropdown  
// modules
import { LayoutPagePreloader } from './modules/layout/page-preloader.js';


// init
document.addEventListener('DOMContentLoaded', () => {

	// layouts
	LayoutPagePreloader.init();
		
});