/**
 * Basic UI: Slideout.
 */
const BasicSlideout = (() => {

	class Core {

	    constructor({trigger, slideout}) {
	        
	        this.$trigger = trigger   || false;
			this.$slideout = slideout;

			this.$options = {
				className: {
					close: 	 'slideout-close',
					wrapper: 'slideout-wrapper',
					content: 'slideout-content',
					status: {
						show: 	'slideout--show',
						active: 'slideout--active',
					}
				}
			}

			this.variables();
			this.build();
			this.events();

	    }


		variables() {

			this.funcClickByArea = (event) => {

                let target = event.target;

                if (
					target.closest(`.${this.$options.className.content}`) == null && 
					target.closest('.slideout-trigger') == null					
				) {
					
					if (this.$trigger !== false) {
						
						this.$trigger.setAttribute('aria-expanded', false);
						this.$trigger.classList.remove(`${this.$options.className.status.active}`);
						
					}

					this.$slideout.classList.remove(`${this.$options.className.status.show}`);	
					
					document.body.classList.remove('body-slideout');
                    document.removeEventListener('click', this.funcClickByArea, false);

                }

            }

		}


		build() {

			if (this.$slideout.classList.contains('slideout-init') == false) {

				this.build_slideout();
				this.build_trigger();																

			}

		}


		build_slideout() {

			let buttonClose = document.createElement('button');
			let content = document.createElement('div');
			

			buttonClose.innerHTML = 
				`<svg width="512" height="512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M5.35576 5.35576C12.4968 -1.78525 24.0747 -1.78525 31.2157 5.35576L256 230.14L480.784 5.35576C487.925 -1.78525 499.503 -1.78525 506.644 5.35576C513.785 12.4968 513.785 24.0747 506.644 31.2157L281.86 256L506.644 480.784C513.785 487.925 513.785 499.503 506.644 506.644C499.503 513.785 487.925 513.785 480.784 506.644L256 281.86L31.2157 506.644C24.0747 513.785 12.4968 513.785 5.35576 506.644C-1.78525 499.503 -1.78525 487.925 5.35576 480.784L230.14 256L5.35576 31.2157C-1.78525 24.0747 -1.78525 12.4968 5.35576 5.35576Z"/>
				</svg>`;
			buttonClose.classList.add(this.$options.className.close);
			buttonClose.setAttribute('type', 'button');
			buttonClose.setAttribute('title', 'Close slideout panel');
			buttonClose.setAttribute('aria-label', 'Close slideout panel');

			content.classList.add(this.$options.className.content);
			content.innerHTML = `
				<div class="slideout-header">${buttonClose.outerHTML}</div>
				<div class="slideout-main"></div>`;
			while (this.$slideout.firstChild) content.querySelector('.slideout-main').appendChild(this.$slideout.firstChild);


			this.$slideout.appendChild(content);
			this.$slideout.classList.add('slideout');
			this.$slideout.classList.add('slideout-init');

		}


		build_trigger() {

			if (this.$trigger !== false) {
															
				if (this.$trigger.getAttribute('id') == null) {
					
					this.$trigger.setAttribute('id', `trigger-slideout-${Array.from(document.querySelectorAll('.slideout-trigger')).indexOf(this.$trigger)}`);

				}
									
				this.$trigger.setAttribute('aria-haspopup', true);
				this.$trigger.setAttribute('aria-expanded', false);										
				this.$trigger.setAttribute('aria-controls', this.$slideout.getAttribute('id'));										
				this.$trigger.classList.add('slideout-trigger');
				
				this.$slideout.setAttribute('aria-labelledby', this.$trigger.getAttribute('id'));
			
			}

		}


		events() {

			if (this.$trigger !== false) {

				this.$trigger.addEventListener('click', this.listener_slideout_show.bind(this), false);

			}
			
			this.$slideout.addEventListener('click', this.listener_slideout_hide.bind(this), false);

		}


		listener_slideout_show(event) {

			let status = false,
				target = event.target,
				outsideCLick = this.$slideout.getAttribute('data-slideout-click-outside') == null || this.$slideout.getAttribute('data-slideout-click-outside') !== 'false';


			document.body.classList.toggle('body-slideout');
			this.$slideout.classList.toggle(this.$options.className.status.show);						
			
			status = this.$slideout.classList.contains(this.$options.className.status.show);
									
			this.$trigger.setAttribute('aria-expanded', status);
			this.$trigger.classList.toggle(this.$options.className.status.active);


			// outside click
			if (status == true && outsideCLick == true) {

				document.addEventListener('click', this.funcClickByArea, false);

			}

		}


		listener_slideout_hide(event) {

			let target = event.target;


			if (target.getAttribute('data-slideout-close') !== null || target.closest(`.${this.$options.className.close}`) !== null) {
				
				if (this.$trigger !== false) {
					
					this.$trigger.setAttribute('aria-expanded', false);
					this.$trigger.classList.remove(`${this.$options.className.status.active}`);
					
				}

				document.body.classList.remove('body-slideout');
				this.$slideout.classList.remove(`${this.$options.className.status.show}`);					

			}

		}


		method_show() {

			this.$slideout.classList.add(this.$options.className.status.show);
			document.addEventListener('click', this.funcClickByArea, false);

		}


		method_hide() {

			this.$slideout.classList.remove(this.$options.className.status.show);
			document.removeEventListener('click', this.funcClickByArea, false);

		}

	}


	let active = null;


	/**
	 * Slideout initialization
	 * @param {object|string} config - element for initializing Slideout
	 */
	const init = (config) => {

		let options = config || {},	
			elements = BasicCore.variables(options.trigger, '.js-slideout-trigger');

			
		try {

			if (elements == false && options.trigger !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && options.trigger !== undefined) throw new Error(BasicCore.logging['error']['type']);

			elements.forEach((value) => {

				options.trigger = value;
				options.slideout = document.querySelector(`#${value.getAttribute('data-slideout-id')}`) || document.querySelector(options.content);

				active = new Core(options);

			});

			return active;

		} catch(error) {

			console.error(`${BasicCore.logging['name']} Slideout init. \nMessage: ${error.message} \nElement: `, options.trigger);

		}	

	};


	/**
	 * Method: Show a .slideout
	 * @param {(Object|string)} content - show a .slideout
	 */
	const show = (slideout) => {

		let options = {
			slideout: (typeof slideout == 'object') ? slideout : document.querySelector(slideout)
		};
				

		try {

			if (options.slideout == null) throw new Error(BasicCore.logging['error']['missing']);
			
			active = new Core(options);
			active.method_show();
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Slideout show. \nMessage: ${error.message} \nElement:`, slideout);

		}	

	};


	/**
	 * Method: Hide a .slideout
	 * @param {(Object|string)} slideout - hide a .slideout
	 */
	const hide = (slideout) => {

		let options = {
			slideout: (typeof slideout == 'object') ? slideout : document.querySelector(slideout)
		};
				

		try {

			if (options.slideout == null) throw new Error(BasicCore.logging['error']['missing']);
			
			active = new Core(options);
			active.method_hide();
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Slideout show. \nMessage: ${error.message} \nElement:`, slideout);

		}	

	};


	return { init, show, hide };

})()
	

window.BasicSlideout = BasicSlideout;


export  { BasicSlideout };